import React from "react"
import { graphql } from "gatsby"

import { classNames } from "../infra/functions"

import EventTitle from "../components/eventTitle"
import Layout from "../components/layout"
import Link from "../components/link"
import Snippet from "../components/snippet"
import MarkdownAsHtml from "../infra/markdownAsHtml"

import { timeSlotText, timeSlotUtcText, trackText, roomText } from "../infra/functions"

import json from "../../content/meta/schedule.json"

import style from "./schedule.module.css"

const SchedulePage = ({ data }) => (
	<Layout
		meta={{
			title: "Schedule",
			description:
				"15 talks and panels on Java, web dev, and operations from simplicity🌱 to generators⚡, from polar bears🐻‍❄️ to killers🔪🩸, from the cloud☁ to the moon🌕, and beyond.",
			keywords: "Java conference Karlsruhe",
			path: "/",
		}}
		wide
	>
		<Snippet id="schedule-intro" />
		{scheduleAll(data.talks.nodes, json.blocks)}
		<Snippet id="schedule-outro" />
	</Layout>
)

const scheduleAll = (talks, blocks) => (
	<div>
		<Snippet id="schedule-table-intro" />
		<div className={style.schedule}>
			{blocks.map(block => scheduleBlock(talks, block))}
		</div>
	</div>
)

const scheduleBlock = (talks, block) => {
	const blockTalks = block.talk ? [block.talk] : block.talks ? block.talks : undefined
	const blockEvents = block.event ? [block.event] : block.events ? block.events : undefined
	const time = { start: block.start, end: block.end }
	return (
		<div key={time.start} className={style.block}>
			<div className={style.blockHeader}>
				<span>{timeSlotText(time.start, time.end)}</span>
				{block.title && <span className={style.blockTitle}>{block.title}</span>}
			</div>
			{block.description && <p className={style.blockDescription}>{block.description}</p>}
			{blockTalks && (
				<div className={style.events}>
					{blockTalks.map((blockTalk, room) =>
						blockTalk ? scheduleTalk(talks, blockTalk, room) : null)}
				</div>
			)}
			{blockEvents && scheduleIntermissions(blockEvents)}
		</div>
	)
}

const scheduleIntermissions = events => {
	if (events.length <= 3)
		return (
			<div className={style.events}>
				{events.map(event => scheduleIntermission(event))}
			</div>
		)

	if (events.length === 4)
		return (
			<React.Fragment>
				<div className={style.events}>
					{events.slice(0, 2).map(event => scheduleIntermission(event))}
				</div>
				<div className={style.events}>
					{events.slice(2).map(event => scheduleIntermission(event))}
				</div>
			</React.Fragment>
		)

	return (
		<React.Fragment>
			<div className={style.events}>
				{events.slice(0, 3).map(event => scheduleIntermission(event))}
			</div>
			<div className={style.events}>
				{events.slice(3).map(event => scheduleIntermission(event))}
			</div>
		</React.Fragment>
	)
}

const scheduleTalk = (talks, slug, room) => {
	const talk = talks.find(event => event.frontmatter.slug === slug)
	if (!talk) throw new Error(`Could not find talk ${slug}.`)
	return scheduleEvent(talk, room)
}

const scheduleEvent = (event, room) => {
	const className = event.fields.collection === "panels" ? style.panel : ""
	return (
		<div key={event.frontmatter.slug} id={event.frontmatter.slug} className={`${style.event} ${className}`}>
			<EventTitle event={event} linkTitle className={style.title} />
			<div className={style.excerpt}>{event.frontmatter.excerpt}</div>
			<div className={style.speakers}>
				{event.frontmatter.speakers.map(speaker => (
					<Link key={speaker.fields.path} className={style.speaker} to={speaker.fields.path}>
						{speaker.frontmatter.name}
					</Link>
				))}
			</div>
			<div className={style.track}>
				<span className={style.trackText}>{trackText(event.frontmatter.track)}</span>
				{room !== undefined && event.frontmatter.track !== "keynote" && <>
					<span className={style.trackDivider}>|</span>
					<span className={style.trackRoom}>{roomText(room)}</span>
				</>}
				{event.frontmatter.track === "keynote" && <>
					<span className={style.trackDivider}>|</span>
					<span>main stage</span>
				</>}
			</div>
		</div>
	)
}

const scheduleIntermission = event => {
	const classes = [ style.event, style.intermission ]
	if (event.intermission.interactive) classes.push(style.interactive)
	const time = event.start ? { start: event.start, end: event.end } : undefined
	return (
		<div id={event.title} {...classNames(...classes)}>
			{time && <div className={style.time}>{timeSlotUtcText(time.start, time.end)}</div>}
			<span className={`${style.title} ${style.intermissionTitle}`}>
				{event.intermission.title}
			</span>
			<div className={style.excerpt}>
				<MarkdownAsHtml>{event.intermission.description}</MarkdownAsHtml>
			</div>
			<div className={style.track}>
				<span>{event.intermission.track ? event.intermission.track : "main stage"}</span>
			</div>
		</div>
	)
}

export const query = graphql`
	query {
		talks: allMarkdownRemark(
			filter: { fields: { collection: { in: ["talks", "panels"] } } }
		) {
			nodes {
				fields {
					path
					collection
				}
				frontmatter {
					title
					excerpt
					track
					slug
					language
					speakers {
						fields {
							path
						}
						frontmatter {
							name
						}
					}
				}
			}
		}
	}
`

export default SchedulePage
